<template>
    <el-drawer
        title="编辑"
        :visible.sync="drawer"
        :modal="true"
        :wrapperClosable="false"
        size="600px"
        @close="closeDrawer"
        :modal-append-to-body="false"
        :direction="direction">

        <!-- title -->
        <div class="drawer_form">
            
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="120px" class="demo-ruleForm">
                
                <div class="form-body">
                    <overlay-scrollbars style="width: 100%;height: 100%;" class="el-fade-in">
                        <el-form-item label="姓名" prop="">
                            <el-input type="text" v-model="form.name" autocomplete="off"></el-input>
                        </el-form-item>
                        <el-form-item label="用户名" prop="">
                            <el-input type="text" v-model="form.username" autocomplete="off" disabled></el-input>
                        </el-form-item>
                        <el-form-item label="密码" prop="">
                            <el-input type="password" v-model="form.password" autocomplete="off" placeholder="可填，默认身份证后六位"></el-input>
                        </el-form-item>
                        <el-form-item label="联系电话" prop="">
                            <el-input type="text" v-model="form.phone" autocomplete="off" placeholder=""></el-input>
                        </el-form-item>
                        
                        <el-form-item label="职称">
                            <el-select v-model="form.titleId" placeholder="请选择" class="w-full">
                                <el-option
                                v-for="item in title_lists"
                                :key="item.dictCode"
                                :label="item.dictValue"
                                :value="item.dictCode">
                                </el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="标签" prop="">
                            <el-tag
                            :key="tag"
                            v-for="tag in form.tags"
                            closable
                            :disable-transitions="false"
                            @close="handleClose(tag)">
                            {{tag}}
                            </el-tag>
                            <el-input
                                
                                class="input-new-tag"
                                v-if="tags_inputVisible"
                                v-model="tags_value"
                                ref="saveTagInput"
                                size="small"
                                @keyup.enter.native="handleInputConfirm"
                                @blur="handleInputConfirm"
                            >
                            </el-input>
                            <el-button v-else class="button-new-tag" size="small" @click="showInput">+ 添加标签</el-button>
                        </el-form-item>
                        <!-- <el-form-item label="角色" prop="">
                            <el-select v-model="form.roleIds" multiple  placeholder="请选择" class="w-full">
                                <el-option
                                v-for="item in role_lists"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item> -->
                        <!-- <el-form-item label="部门ID" prop="">
                            <el-input type="text" v-model="form.name" autocomplete="off" placeholder=""></el-input>
                        </el-form-item> -->
                        <!-- <el-form-item label="头像" prop="">
                            <el-upload
                                class="avatar-uploader"
                                :action="uploadUrl"
                                :headers="{'Authorization':token}"
                                :show-file-list="false"
                                list-type="picture-card"
                                :on-success="handleAvatarSuccess"
                                :before-upload="beforeAvatarUpload">
                                <img v-if="form.avatar" :src="form.avatar" class="avatar">
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            </el-upload>                        
                        </el-form-item> -->
                    </overlay-scrollbars>
                </div>

                <div class="form-foot d-flex a-center j-center">
                    <el-button @click="onSubmit" type="primary" class="" style="width:200px">提交</el-button>
                </div>
            </el-form>
        </div>

    </el-drawer>
    
</template>

<script>
import {getFileUrl} from '@/request/api/login.js'
import {expert_dict,teacher_dict} from '@/request/api/dict.js'
import {update,get_expert} from '@/request/api/expert.js'
export default {
    data(){
        return {
            uploadUrl:'',
            token:'',
            drawer: true, //表单
            direction: 'rtl',//抽屉方向
            loading:false,
            form:{
                id:'',
                name:'', //真实姓名
                username:'', //用户名
                password:'', //密码
                phone:'', //手机
                titleId:'',//职称
                // type:'',//用户类型
                tags:[],//标签
                // deptId:'',//部门ID
                // avatar:'',//头像
            },
            tags_inputVisible:false,
            tags_value:'',
            type_lists:[{label:'教师',value:1},{label:'专家',value:2}],
            role_lists:[],
            title_lists:[], //职称
            rules:{
                type:[
                    { required: true, message: '职称必填', trigger: 'blur' },
                ],
            }
        }
    },
    created(){
        expert_dict().then(res=>{
            this.title_lists = res.data
        })
        getFileUrl().then(res=>{
            this.uploadUrl = res
            this.token = this.$store.state.user.token
        })
        this.form.id = this.$route.query.id || ''
        this.init()
    },
    methods:{
        init(){
            get_expert({id:this.form.id}).then(res=>{
                if(!res.data) return console.error("res.data is null");
                Object.keys(this.form).forEach(item=>{
                    if(typeof this.form[item] == 'string') {
                        this.form[item] = res.data[item]
                    }
                })
                this.form.tags = res.data.tags && res.data.tags.split(',')
                this.form.titleId = res.data.titleId && res.data.titleId+''
            })
        },
        onSubmit(){
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this.req()
                } else {
                    return false;
                }
            });
        },
        handleAvatarSuccess(res, file) {
            // this.imageUrl = URL.createObjectURL(file.raw);
            this.form.avatar = res.data.url
        },
        beforeAvatarUpload(file) {
            // const isJPG = file.type === 'image/jpeg';
            const isLt2M = file.size / 1024 / 1024 < 2;

            // if (!isJPG) {
            // this.$message.error('上传头像图片只能是 JPG 格式!');
            // }
            if (!isLt2M) {
            this.$message.error('上传头像图片大小不能超过 2MB!');
            }
            return isLt2M;
        },
        // getRoles(){
        //     role_list().then(res=>{
        //         this.role_lists = res.data
        //     })
        // },

        // changeType(){
        //     this.title_lists = [];
        //     this.form.titleId = ''
        //     if(this.form.type == 1) {
        //         teacher_dict().then(res=>{
        //             this.title_lists = res.data
        //         })
        //     }else{
        //         expert_dict().then(res=>{
        //             this.title_lists = res.data
        //         })
        //     }    
        // },

        //req
        req(){
            let form = JSON.parse(JSON.stringify(this.form))
            form.tags = form.tags.join(',')
            update(form).then(res=>{
                if(res.code!=0) return this.$notify.error({title:'错误',message: res.msg});
                this.$notify({title: '成功',message: res.msg,type: 'success'});
                this.$router.go(-1)
            })
        },
        handleClose(tag) {
            this.form.tags.splice(this.form.tags.indexOf(tag), 1);
        },
        showInput() {
            this.tags_inputVisible = true;
            this.$nextTick(_ => {
                this.$refs.saveTagInput.$refs.input.focus();
            });
        },
        handleInputConfirm() {
            let inputValue = this.tags_value;
            if (inputValue) {
                this.form.tags.push(inputValue);
            }
            this.tags_inputVisible = false;
            this.tags_value = '';
        },

        //重置表单
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },

        //关闭抽屉
        closeDrawer(){
            this.$router.go(-1)
        }
    }

}
</script>


<style>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>

<style lang="less" scoped>
.drawer_form {
    width: 96%;
    margin: auto;
    .drawer_form_title {
        color: #17233d;
        font-size: 22px;
        font-weight: 400;
        text-align: center;
        line-height: 48px;
        margin-bottom: 20px;
    }

    .drawer_line {
        margin-bottom: 30px;
        .drawer_line_text {
            width: 120px;
            margin-right: 20px;
            text-align: right;
            color: #515a6e;
        }    
    }

}
.form-items {
    background-color:#f5f6fb;padding:15px 10px;height:500px;
}

/deep/ .form-items .el-form-item ,/deep/ .form-items .el-form-item .el-form-item__content{
    width: 100%;
}
.el-tag + .el-tag {
    margin-left: 10px;
}
.button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
}
.input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
}
</style>

